import PropTypes from "prop-types";
import React from "react";
import Image from '@components/utility/Image';
import "./styles.scss";

const SideEffects = ({image}) => {
	return (
		<div className="relative p-2 side-effects">
			<h2 className="text-center uppercase font-bold mb-3">
				<span className="text-2xl text-emflaza-orange-100 font-gotham lg:text-3xl block mb-1">Common side effects with emflaza</span>
				<span className="font-roboto text-lg block">% of patients taking deflazacort 0.9&nbsp;<span className="normal-case ">mg/kg/day</span> at 12&nbsp;weeks</span>
			</h2>
			<div className="container--list">
				<ul className="flex items-center lg:space-x-16 desktop:space-x-0 desktop:justify-around 4xl:justify-start 4xl:space-x-16">
					<li className="">Facial puffiness<br className="hidden xl:block" /> or Cushingoid appearance <strong>33%</strong></li>
					<li className="">Frequent daytime urination <strong>12%</strong></li>
					<li className="">Common cold <strong>10%</strong></li>
					<li className="">Abdominal discomfort <strong>6%</strong></li>
				</ul>
				<ul className="flex items-center max-width">
					<li className="md:px-6">Weight<br className="hidden xl:block" /> increased <strong>20%</strong></li>
					<li className="md:px-6">Upper respiratory<br className="hidden xl:block" /> tract infection <strong>12%</strong></li>
					<li className="md:px-6">Irritability <strong>8%</strong></li>
				</ul>
				<ul className="flex items-center max-width">
					<li className="md:px-6">Increased<br className="hidden xl:block" /> appetite <strong>14%</strong></li>
					<li className="md:px-6">Unwanted hair growth&nbsp;<strong>10%</strong></li>
					<li className="md:px-6">Runny nose <strong>8%</strong></li>
				</ul>
				<ul className="flex items-center max-width">
					<li className="md:px-6">Cough <strong>12%</strong></li>
					<li className="md:px-6">Central obesity <strong>10%</strong></li>
					<li className="md:px-8">Skin redness <strong>8%</strong></li>
				</ul>
			</div>
			<Image imageData={image} />
		</div>
	);
};

export default SideEffects;

SideEffects.propTypes = {
    image: PropTypes.object.isRequired,
}
