import React from 'react';
import './styles.scss';

const SparklesBottomCta = () => {
	return (
		<div className="sparkles sparkles-bottom-cta">
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-1" />
			<img src="/images/icons/switch/circle-navy.svg" className="animated-icon navy-1" alt="" />
			<img src="/images/icons/switch/plus-blue.svg" className="animated-icon blue-1" alt="" />
			<img src="/images/icons/cta-blue-2.png" className="animated-icon blue-2" alt="" />
			<img src="/images/icons/cta-blue-3.png" className="animated-icon blue-3" alt="" />
			<img src="/images/icons/switch/circle-blue.svg" className="animated-icon blue-4" alt="" />
			<img src="/images/icons/cta-orange-2.png" className="animated-icon orange-2" alt="" />
			<img src="/images/icons/cta-orange-3.png" className="animated-icon orange-3" alt="" />
			<img src="/images/icons/cta-white-1.png" className="animated-icon white-1" alt="" />
			<img src="/images/icons/cta-white-2.png" className="animated-icon white-2" alt="" />
		</div>
	);
};

export default SparklesBottomCta;
