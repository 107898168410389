import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Loadable from "@loadable/component";
import {
	KfButton,
	KfHeroUtility,
	KfAccordion,
	KfAccordionItem,
} from "@klickinc/kf-react-components";
// Utility imports
import Image from "@components/utility/Image";
import Seo from "@components/utility/SEO";
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
// Content imports
import BottomCta from "@components/content/BottomCta";
import SideEffects from "@components/content/side-effects-table/SideEffectsTable";
import SideEffectsMobile from "@components/content/side-effects-table/SideEffectsTableMobile";
import GTM from '@utils/GTM';
import { handleFBQ } from '@utils/FBQ';
import "./styles.scss";

//Content Imports
const WhizzbangLine = Loadable(() => import("@components/content/WhizzbangLine"));

const SafetyProfile = ({data}) => {
	const ctx = useContext(AppContext);

	const image_whizzbang = getImage(data.whizzbang);
	const image_sideEffects = getImage(data.sideEffects);
	const image_heroBg = getImage(data.heroBg);
	const image_poly = getImage(data.poly);
	const image_accIcon2016 = getImage(data.accIcon2016);
	const image_externalLink = getImage(data.externalLink);

	const closeAccordion = (id) => {
		const el = document.querySelector(`#${id}`);
		if (el) {
			const trigger = el.querySelector(".kf-accordion__trigger button");
			if (trigger) trigger.click();
		}
	};


	const accToggleForOtherEls = (e) => {
		const accState = e.target.getAttribute('aria-expanded') === 'true';
		const page = document.querySelector('.page--safety-profile');
		if (accState) {
			if (page) page.classList.add('page-expanded-acc');
		} else if (!accState) {
			if (page) page.classList.remove('page-expanded-acc');
		}
	}

	const handleToggle = (e) => {
		const trigger = e.target;
		const expanded = trigger.getAttribute('aria-expanded') === 'true';
		const parent = trigger.closest('.kf-accordion');

		if (parent) {
			const id = parent.id.replace(/-1-|-2-|-3-/g, ' ').replace('-', ' ');
			const label = `See ${id}`;
			GTM.accordionToggle(expanded, label);
		}

		if (expanded) {
			trigger.scrollIntoView();
		}
	};

	useEffect(() => {
		ctx.setPageClass("page--safety-profile");

		const accBtn = document.querySelector('#study-summary-1-2016 .kf-accordion__trigger button');
		if (accBtn) accBtn.addEventListener('click', (e) => accToggleForOtherEls(e));

		const triggers = document.querySelectorAll('.kf-accordion__trigger button');
		triggers.forEach(trigger => {
			trigger.addEventListener('click', (e) => {
				handleToggle(e, trigger)

				const expanding = e.target.getAttribute('aria-expanded') === 'false';
				if (expanding) {
					handleFBQ('Search');
				}
			});
		});

        return () => {
			if (accBtn) accBtn.removeEventListener('click', () => accToggleForOtherEls)
			triggers.forEach(trigger => {
				trigger.removeEventListener('click', (e) => {
					handleToggle(e, trigger)
				});
			});
        }
    }, [])

	return (
		<>
			<section className="mb-12 sm:mb-40 section-1-hero md:mb-32 3xl:mb-64 relative">
				<div className='hero-bg-desktop'>
					<Image
						imageData={image_heroBg}
					/>
				</div>
				<div className="hero-bg">
				</div>
				<div className='hero-bg-1'></div>
				<div className="md:pl-10">
					<div className="container section-1 container--inner pb-2 md:mb-0 ">
						<KfHeroUtility addedClass="heading-hero-blue heading">
							Safety Profile
						</KfHeroUtility>
						<p className="pb-6 lg:max-w-2xl lg:ml-0">
							There are common side effects that could occur with
							EMFLAZA. In a study of safety, the listed effects
							happened at a rate greater than 5%.
						</p>
					</div>
					<div className="md:container md:container--inner ">
						<div className="md:block hidden">
							<SideEffects image={image_sideEffects}/>
						</div>
						<div className="block md:hidden">
							<SideEffectsMobile image={image_sideEffects}/>
						</div>
					</div>

					<div className="container pt-2 container--inner">
						<p className="mt-6 lg:max-w-2xl">Please seek treatment immediately and tell your son's healthcare provider if your child has any of these symptoms or any other side effects.</p>
					</div>
				</div>
			</section>

			<section className="relative pl-4 md:pl-10 ">
				<div className="safety-poly-2 overflow-hidden z-negative mr-10 absolute -left-10 md:-left-2 bottom-0">
					<Image
						imageData={image_poly}
					/>
				</div>
				<div className="container relative container--inner">
					<div className="xl:mr-44">
						<div className="space-y-3">
							<h2 className="heading-hero-blue heading min-w-[320px] w-[90%] md:w-11/12 xl:w-full">
								Consider his behavior and weight&#8212;here, now, and
								tomorrow
								<WhizzbangLine
									image={image_whizzbang}
									addedClass="whizzbang"
								/>
							</h2>
							<p>
								The deflazacort molecule is different from other
								corticosteroids and was developed to provide a
								more tolerable treatment option.
							</p>
							<p className="font-bold">
								In a 52-week clinical trial of 196 boys aged 5
								to 15 years with DMD, the effectiveness and
								safety of EMFLAZA were compared with prednisone.
								In comparing adverse events between deflazacort <br className='hidden desktop:inline'/>
								0.9 mg/kg/day and prednisone 0.75 mg/kg/day, the
								following was found:
							</p>
							<ul className="bulleted-list sublist-no-bullet">
								<li className="bullet-list-item">
									Fewer boys taking deflazacort experienced
									abnormal behavior compared to those taking
									prednisone
								</li>
								<li>
									<ul className="circled-list">
										<li>
											Deflazacort: 8.8% (n=6/68); prednisone:
											14.3% (n=9/63)
										</li>
									</ul>
								</li>
								<li className="bullet-list-item">
									Boys taking deflazacort experienced less
									weight gain compared to those taking
									prednisone
								</li>
								<li>
									<ul className="circled-list">
										<li>
											Deflazacort: 27.9% (n=19/68);
											prednisone: 34.9% (n=22/63)
										</li>
									</ul>
								</li>
								<li className="bullet-list-item">
									More boys taking deflazacort experienced
									cataracts compared to those taking
									prednisone (4.4% vs 1.6%)
								</li>
								<li>
									<ul className="circled-list">
										<li>
											No cataracts were considered serious
											events
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<div className="accordion">
						<Image
							imageData={image_accIcon2016}
							alt="2016 Study"
						/>
						<div className="acc-inner">
							<KfAccordion addedId="study-summary-1-2016">
								<KfAccordionItem
									expandLabel="See Study Summary +"
									collapseLabel="Close Study Summary -"
								>
									<h4 >
										GRIGGS 2016 STUDY SUMMARY
									</h4>
									<p className="font-bold">
										Efficacy and safety of deflazacort
										vs prednisone and placebo for
										Duchenne muscular dystrophy.
									</p>
									<p>
										<span>Objective:</span>&nbsp; To
										assess safety and efficacy of
										deflazacort (DFZ) and prednisone
										(PRED) vs placebo in DMD.
									</p>
									<p>
										<span>Methods:</span>&nbsp; This
										phase III, double-blind, randomized,
										placebo-controlled, multicenter
										study evaluated muscle strength
										among 196 boys aged 5 - 15 years
										with DMD during a 52-week period.
										The study was completed in&nbsp;1995.
									</p>
									<p>
										<span>Results:</span>&nbsp; After 12
										weeks of treatment, PRED and both
										doses of DFZ improved muscle
										strength compared with placebo. DFZ
										was associated with less weight gain
										than PRED.
									</p>
									<KfButton
										clickFn={() =>
											closeAccordion(
												"study-summary-1-2016"
											)
										}
									>
										Close Study Summary -
									</KfButton>
								</KfAccordionItem>
							</KfAccordion>
						</div>
					</div>
					<div className="xl:mr-44">
						<div className="items-center justify-center my-3 space-x-3 align-middle md:flex-nowrap md:flex ">
							<div>
								<h3 className="heading-bg mt-0 mb-2 md:mb-8 md:mt-4 md:ml-4 md:mr-44">How can a healthy<br className='block lg:hidden' /> diet <br className='hidden lg:block' /><span className="whitespace-pre">complement<br className='block lg:hidden' /> treatment?</span>
								</h3>
							</div>
							<a
								className="flex md:flex-none justify-center items-center align-middle md:pl-6 text-emflaza-blue-100  hover:text-emflaza-blue-600 focus:text-emflaza-blue-600"
								target="_blank"
								href="https://www.cureduchenne.org/blog/every-bit-matters-nutritional-suggestions-duchenne-patients"
								rel="noreferrer noopener"
							>
								<span className="text-2xl italic font-black underline uppercase font-gotham mr-4">
									Find Out
								</span>
								<span aria-hidden='true'>
									<Image
										imageData={image_externalLink}
										alt=""
									/>
								</span>
							</a>
						</div>
					</div>
					<div className="pb-24 mt-12 md:mt-20 md:pb-20">
						<BottomCta
							image={data}
							ctaUrl="/dosing"
						>
							Dosing
						</BottomCta>
					</div>
				</div>
			</section>
		</>
	);
};

export default SafetyProfile;



export const pageQuery = graphql`
  query {
	whizzbang: file(relativePath: {eq: "pages/safety-profile/whizzbang-line.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	heroBg: file(relativePath: {eq: "pages/safety-profile/hero-bg.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	sideEffects: file(relativePath: {eq: "pages/safety-profile/side-effects-man.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	poly: file(relativePath: {eq: "pages/safety-profile/safety-profile-poly2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	accIcon2016: file(relativePath: {eq: "icons/acc-icon-2016.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	externalLink: file(relativePath: {eq: "pages/safety-profile/external-link.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/safety-profile/bottom-cta/safety-bottom-cta-sparkle-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/safety-profile/bottom-cta/safety-bottom-cta-sparkle-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/safety-profile/bottom-cta/safety-bottom-cta.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/safety-profile/bottom-cta/safety-bottom-cta-sparkle-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/safety-profile/bottom-cta/safety-bottom-cta-sparkle-2-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
    return (
		<Seo
			title="Safety & Side Effects - EMFLAZA® (deflazacort)"
			description="See the safety profile of EMFLAZA® (deflazacort) and find out about common side effects. See full Prescribing Information & Important Safety Information."
		/>
    );
};
