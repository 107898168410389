import PropTypes from "prop-types";
import React from "react";
import Image from '@components/utility/Image';
import "./styles.scss";

const SideEffectsMobile = ({image}) => {
	return (
		<div className="relative p-2 side-effects-mobile">
			<h2 className="text-center uppercase font-bold mb-3">
				<span className="text-2xl text-emflaza-orange-100 font-gotham lg:text-3xl block mb-1">Common side effects with emflaza</span>
				<span className="font-roboto text-lg block">% of patients taking deflazacort 0.9&nbsp;<span className="normal-case ">mg/kg/day</span> at 12&nbsp;weeks</span>
			</h2>
			<div className="container--list">
				<ul className="flex items-center justify-around">
					<li className="">Facial puffiness or Cushingoid appearance <strong>33%</strong></li>
					<li className="">Frequent daytime urination <strong>12%</strong></li>
				</ul>
				<ul className="flex items-center justify-around">
					<li className="">Common cold <strong>10%</strong></li>
					<li className="">Abdominal discomfort <strong>6%</strong></li>
				</ul>
				<ul className="flex items-center justify-around">
					<li className="md:px-6">Weight increased <strong>20%</strong></li>
					<li className="md:px-6">Upper respiratory tract infection <strong>12%</strong></li>
				</ul>
				<ul className="flex items-center justify-around">
					<li className="md:px-6">Irritability <strong>8%</strong></li>
					<li className="md:px-6">Increased appetite <strong>14%</strong></li>
				</ul>
				<ul className="flex items-center justify-around">
					<li className="md:px-6">Unwanted hair growth&nbsp;<strong>10%</strong></li>
					<li className="md:px-6">Runny nose <strong>8%</strong></li>
				</ul>
				<ul>
					<li className="md:px-6 extra-padding">Cough <strong>12%</strong></li>
				</ul>
				<ul>
					<li className="md:px-6 extra-padding">Central obesity <strong>10%</strong></li>
				</ul>
				<ul>
					<li className="md:px-8 extra-padding">Skin redness <strong>8%</strong></li>
				</ul>
			</div>
			<Image imageData={image} />
		</div>
	);
};

export default SideEffectsMobile;

SideEffectsMobile.propTypes = {
    image: PropTypes.object.isRequired,
}
