import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Image from '@components/utility/Image';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import SparklesBottomCta from '@components/content/svgs/SparklesBottomCta';

const BottomCta = ({ctaUrl, image, children, addedClass, hasAnchor, target}) => {
	const leftImage = withArtDirection(getImage(image.leftImage), [
		{
			media: "(max-width: 640px)",
			image: getImage(image.leftImageMobile),
		},
	]);
	const iconImage = getImage(image.iconImage);
	const rightImage = withArtDirection(getImage(image.rightImage), [
		{
			media: "(max-width: 640px)",
			image: getImage(image.rightImageMobile),
		},
	]);

	const bottomCTAcontent = () => {
		return(
			<>
				<div className='cta-image-1' aria-hidden='true'>
					<div className='block lg:hidden'>
						<Image imageData={leftImage} objectFit='contain' alt=""/>
					</div>
					<div className='hidden lg:block'>
						<SparklesBottomCta />
					</div>
				</div>

				<div className='cta-image-2' aria-hidden='true'>
					<Image imageData={iconImage} objectFit='contain' alt=""/>
				</div>

				<span className='mx-4 text-xs font-bold uppercase font-gotham lg:text-base lg:mx-0'>{children}</span>

				<div className='cta-chevron' aria-hidden='true'>
					<div className="inner"></div>
				</div>

				<div className='cta-image-3' aria-hidden='true'>
					<Image imageData={rightImage} objectFit='contain' alt=""/>
				</div>
			</>
		)
	}

    return hasAnchor ?
		<a href={ctaUrl} target={target} className={`cta ${addedClass}`}>{bottomCTAcontent()}</a>
		:
		<Link to={ctaUrl} className={`cta ${addedClass}`}>{bottomCTAcontent()}</Link>
}

export default BottomCta;

BottomCta.defaultProps = {
    addedClass: '',
	hasAnchor: false,
	target: '_self',
}

BottomCta.propTypes = {
    image: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    addedClass: PropTypes.string,
	hasAnchor: PropTypes.bool,
	target: PropTypes.string,
}
